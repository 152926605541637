import React from 'react';

import Layout from '../containers/layoutContainer';
import LoginView from '../containers/loginContainer';

const IndexPage = (): React.ReactElement => (
  <Layout>
    <LoginView />
  </Layout>
);

export default IndexPage;
